import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '@tradetrax/web-common';
import { getTrades } from './SettingsContext';
import { NewCustomRoleModal } from './CustomRoles/NewCustomRoleModal';
import { subsService } from 'services';
import moment from 'moment';

export { deleteRelevantTask, deleteLeadTimeTask } from '../Users/MyProfile/MyProfileActions';

// Settings
export function readGTL() {
  return subsService
    .readGTL({}, { query: { isChildTask: 'all' } })
    .then(fromJS)
    .then(response => state => state.set('globalTasks', response));
}

markAsSync(setGTLTrades);
export function setGTLTrades(state, gtlTrades) {
  return state.set('gtlTrades', gtlTrades);
}

markAsSync(setCompanyInfo);
export function setCompanyInfo(state, companyInfo) {
  return state.set('trades', getTrades(companyInfo)).set('companyInfo', companyInfo);
}

markAsSync(updateTrades);
export function updateTrades(state, trades) {
  this.appController.updateSettings({ trades });
  return state.set('trades', trades);
}

markAsSync(setTab);
export function setTab(state, tab) {
  return state.set('tab', tab);
}

markAsSideEffect(setEnableCheckInOutForTrades);
export function setEnableCheckInOutForTrades(isChecked) {
  subsService.updateSettings({ checkInCheckOutForNonInstallerEnabled: isChecked }).catch(err => {
    this.alert.error({ message: 'There was a problem updating this setting. Please try again.' });

    this.appController.dispatch([
      state => state.setIn(['account', 'checkInCheckOutForNonInstallerEnabled'], !isChecked),
    ]);
  });

  this.appController.dispatch([state => state.setIn(['account', 'checkInCheckOutForNonInstallerEnabled'], isChecked)]);
}

// Custom Roles
markAsSideEffect(openCreateRoleModal);
export async function openCreateRoleModal() {
  const { isAccept, name } = await this.modal.open(NewCustomRoleModal, { customRoles: this.state.get('customRoles') });
  if (!isAccept) return;

  subsService
    .createRole({ name })
    .then(fromJS)
    .then(response => response.set('tab', 'my-tasks'))
    .then(response => {
      this.alert.success({ message: 'Custom Role successfully created.' });
      this.controller.dispatch([
        state =>
          viewModel(
            state.update('customRoles', customRoles => customRoles.push(response)).set('roleId', response.get('_id'))
          ),
      ]);
    })
    .catch(() => this.alert.error({ message: 'There was a problem creating this Custom Role. Please try again.' }));
}

markAsSync(toggleRoleActive);
export function toggleRoleActive(state, customRole) {
  const roleId = customRole.get('_id');
  const index = state.get('customRoles').findIndex(cr => cr.get('_id') === roleId);
  const { active } = customRole.toObject();

  subsService
    .updateRole({ active: !active }, { params: { roleId } })
    .then(() => {
      this.alert.success({ message: `Custom Role successfully ${active ? 'd' : 'r'}eactivated.` });
    })
    .catch(() => {
      this.alert.error({
        message: `There was a problem ${active ? 'd' : 'r'}eactivating this Custom Role. Please try again.`,
      });
      this.controller.dispatch([state => viewModel(state.setIn(['customRoles', index, 'active'], active))]);
    });

  return viewModel(
    state.updateIn(['customRoles', index], customRole => customRole.set('active', !active).set('tab', 'my-tasks'))
  );
}

export function readCustomRoles() {
  return subsService
    .listRoles({}, { query: { active: 'all' } })
    .then(fromJS)
    .then(response => response.map(role => role.set('tab', 'my-tasks')))
    .then(response => state => viewModel(state.set('customRoles', response)));
}

const viewModel = state => {
  const customRoles = state.get('customRoles');
  const active = customRoles.filter(role => role.get('active'));
  const inactive = customRoles.filter(role => !role.get('active'));
  return state.set('active', active.sort(byDescOrder)).set('inactive', inactive.sort(byDescOrder));
};

const byDescOrder = (a, b) => {
  if (a.get('name') > b.get('name')) return 1;
  if (a.get('name') < b.get('name')) return -1;
  return 0;
};

markAsSync(updateRoleName);
export function updateRoleName(state, customRole, name) {
  const index = state.get('customRoles').findIndex(role => role.get('_id') === customRole.get('_id'));
  return state.setIn(['customRoles', index, 'name'], name);
}

// My Tasks
markAsSync(updateNotificationEventsSettings);
export function updateNotificationEventsSettings(state, onlyChannels, customRole) {
  const channels = (onlyChannels || []).filter(channel => !!channel);
  const { roleId, index } = this.getInfo();
  const notifications = {
    notificationsChannels: channels,
  };

  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([
      state =>
        viewModel(
          state.setIn(
            ['customRoles', index, 'settings', 'notifications', 'notificationsChannels'],
            customRole.getIn(['settings', 'notifications', 'notificationsChannels'])
          )
        ),
    ]);
  });

  return state.setIn(['customRoles', index, 'settings', 'notifications', 'notificationsChannels'], fromJS(channels));
}

markAsSync(updateNotificationEvents);
export function updateNotificationEvents(state, customRole, event, { onlyChannels, notice_window, noticeTime }) {
  const { roleId, index } = this.getInfo();
  const eventState = customRole.getIn(['settings', 'notifications', event]);
  let payload = eventState;

  if (event === 'date-change') {
    const current = eventState.get('notice_window') || 7;
    payload = payload.set('notice_window', notice_window || current);
  } else if (event === 'task-missed-check-in') {
    const current = eventState.get('noticeTime') || 14;
    const selectedTime = noticeTime && moment(noticeTime, 'hha').hours();
    payload = payload.set('noticeTime', selectedTime || current);
  }
  if (onlyChannels) {
    payload = payload.set(
      'onlyChannels',
      onlyChannels.filter(channel => !!channel)
    );
  }

  subsService.updateRole({ notifications: { [event]: payload.toJS() } }, { params: { roleId } }).catch(error => {
    this.controller.dispatch([
      state => state.setIn(['customRoles', index, 'settings', 'notifications', event], eventState),
    ]);
  });

  return state.setIn(['customRoles', index, 'settings', 'notifications', event], payload);
}

// Relevant Tasks
markAsSync(addRelevantTask);
export function addRelevantTask(state, task) {
  const { roleId, index, relevantTasks } = this.getInfo();
  const notifications = {
    relevantTasks: relevantTasks
      .push(task)
      .map(formatRelevantTask)
      .toJS(),
  };

  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was a problem adding this Relevant Task. Please try again.' });
    this.controller.dispatch([
      state => state.setIn(['customRoles', index, 'settings', 'notifications', 'relevantTasks'], relevantTasks),
    ]);
  });

  return state.updateIn(['customRoles', index, 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
    relevantTasks.unshift(relevantTaskViewModel(task))
  );
}

markAsSideEffect(doDeleteRelevantTask);
export function doDeleteRelevantTask(task) {
  const { roleId, index: crIndex, relevantTasks } = this.getInfo();
  const index = relevantTasks.indexOf(task);
  const updated = index > -1 ? relevantTasks.splice(index, 1) : relevantTasks;
  const notifications = {
    relevantTasks: updated.map(formatRelevantTask).toJS(),
  };
  this.controller.dispatch([
    state =>
      state.updateIn(['customRoles', crIndex, 'settings', 'notifications', 'relevantTasks'], relevantTasks =>
        relevantTasks.splice(index, 1)
      ),
  ]);
  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Relevant Task list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Relevant Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRoles', crIndex, 'settings', 'notifications', 'relevantTasks'], relevantTasks),
      ]);
    });
}

markAsSync(updateRelevantTask);
export function updateRelevantTask(state, task, event, channels, extra) {
  const { roleId, index: crIndex, relevantTasks } = this.getInfo();
  const index = relevantTasks.indexOf(task);
  const update = task =>
    task.setIn(
      [event, 'onlyChannels'],
      channels.filter(x => !!x)
    );
  const body = update(task);
  const payload = {
    [event]: body.get(event).toJS(),
  };
  const notice_window = extra?.notice_window;
  if (event === 'start-date-or-end-date') payload['start-date-or-end-date'].notice_window = notice_window || 7;

  const notifications = {
    relevantTasks: relevantTasks
      .update(index, relevantTask => relevantTask.merge(payload))
      .map(formatRelevantTask)
      .toJS(),
  };
  subsService.updateRole({ notifications }, { params: { roleId } }).catch(error => {
    this.alert.error({ message: 'There was an error updating the Relevant Task' });
    this.controller.dispatch([
      state => state.setIn(['customRoles', crIndex, 'settings', 'notifications', 'relevantTasks'], relevantTasks),
    ]);
  });

  const newState = notice_window
    ? state.setIn(
        ['customRoles', crIndex, 'settings', 'notifications', 'relevantTasks', index, event, 'notice_window'],
        notice_window
      )
    : state;
  return newState.updateIn(['customRoles', crIndex, 'settings', 'notifications', 'relevantTasks', index], update);
}

const formatRelevantTask = gtlTask => {
  let task = gtlTask;
  const onlyChannels = { onlyChannels: [] };
  if (!task.get('completed')) task = task.set('completed', onlyChannels);
  if (!task.get('in-progress')) task = task.set('in-progress', onlyChannels);
  if (!task.get('start-date-or-end-date'))
    task = task.set('start-date-or-end-date', { onlyChannels: [], notice_window: 7 });
  if (!task.get('check-in-or-check-out')) task = task.set('check-in-or-check-out', onlyChannels);
  if (!task.get('commitment-request-accepted')) task = task.set('commitment-request-accepted', onlyChannels);
  return task
    .delete('_id')
    .delete('__v')
    .delete('children')
    .delete('trade');
};

const relevantTaskViewModel = task => {
  const onlyChannels = fromJS({ onlyChannels: [] });
  return task
    .set('completed', onlyChannels)
    .set('in-progress', onlyChannels)
    .set('start-date-or-end-date', fromJS({ onlyChannels: [], notice_window: 7 }))
    .set('check-in-or-check-out', onlyChannels)
    .set('commitment-request-accepted', onlyChannels);
};

// Lead Time Tasks
export function createLeadTimeTask({ task }) {
  const { roleId, index, leadTimeTasks } = this.getInfo();
  const notifications = {
    leadTimeTasks: leadTimeTasks
      .push(task)
      .map(formatLeadTimeTask)
      .toJS(),
  };

  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRoles', index, 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.unshift(task)
      )
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem adding this Task. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRoles', index, 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
      throw error;
    });
}

markAsSideEffect(doDeleteLeadTimeTask);
export function doDeleteLeadTimeTask(task) {
  const { roleId, index: crIndex, leadTimeTasks } = this.getInfo();
  const index = leadTimeTasks.indexOf(task);
  const updated = index > -1 ? leadTimeTasks.splice(index, 1) : leadTimeTasks;
  const notifications = {
    leadTimeTasks: updated.map(formatLeadTimeTask).toJS(),
  };

  this.controller.dispatch([
    state =>
      state.updateIn(['customRoles', crIndex, 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks =>
        leadTimeTasks.splice(index, 1)
      ),
  ]);

  subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() =>
      this.alert.success({
        message: 'Task successfully removed from the Lead Time list.',
      })
    )
    .catch(error => {
      this.alert.error({ message: 'There was a problem removing this Task from the list. Please try again.' });
      this.controller.dispatch([
        state => state.setIn(['customRoles', crIndex, 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
    });
}

export function updateLeadTimeTask(task, channels, leadTimeDays) {
  const { roleId, index: crIndex, leadTimeTasks } = this.getInfo();
  const index = leadTimeTasks.indexOf(task);
  const update = task =>
    task
      .setIn(
        ['onlyChannels'],
        channels.filter(x => !!x)
      )
      .setIn(['leadTimeDays'], leadTimeDays);
  const updated = update(task);
  const payload = {
    leadTimeDays: updated.get('leadTimeDays'),
    onlyChannels: updated.get('onlyChannels'),
  };

  const notifications = {
    leadTimeTasks: leadTimeTasks
      .update(index, leadTimeTasks => leadTimeTasks.merge(payload))
      .map(formatLeadTimeTask)
      .toJS(),
  };

  return subsService
    .updateRole({ notifications }, { params: { roleId } })
    .then(() => state =>
      state.updateIn(['customRoles', crIndex, 'settings', 'notifications', 'leadTimeTasks', index], update)
    )
    .catch(() => {
      this.alert.error({ message: 'There was an error updating the Lead Time Task.' });
      this.controller.dispatch([
        state => state.setIn(['customRoles', crIndex, 'settings', 'notifications', 'leadTimeTasks'], leadTimeTasks),
      ]);
    });
}

const formatLeadTimeTask = gtlTask => {
  let task = gtlTask;
  if (!task.get('onlyChannels')) task = task.set('onlyChannels', []);
  if (!task.get('leadTimeDays')) task = task.set('leadTimeDays', 0);
  if (!task.get('isChildTask')) task = task.set('isChildTask', false);
  if (!task.get('gtlTaskId')) task = task.set('gtlTaskId', task.get('_id'));
  return task
    .delete('_id')
    .delete('__v')
    .delete('children')
    .delete('parentTaskId')
    .delete('parentTaskName')
    .delete('trade');
};
