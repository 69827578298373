import React from 'react';
import { fromJS, List } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/AppContext';
import * as actions from './SettingsActions';

export const getTrades = companyInfo => (companyInfo.get('trades') || List()).toArray();

const emptyState = fromJS({
  tab: 'company',
  trades: [],
  companyInfo: {},
  customRoles: { isLoading: true },
  active: [],
  inactive: [],
  roleId: null,
  globalTasks: [],
  gtlTrades: [],
});

export const SettingsContext = () => {
  const appContext = useAppContext();
  const { appState, account: companyInfo } = appContext;
  const gtlTrades = appState.get('trades');
  const initState = React.useMemo(() => emptyState.set('trades', getTrades(companyInfo)), [companyInfo]);
  const [state, controller] = useController(actions, initState, { ...appContext });

  React.useEffect(() => {
    controller.readCustomRoles();
    controller.readGTL();
  }, [controller]);

  React.useEffect(() => {
    if (gtlTrades.size) controller.setGTLTrades(gtlTrades);
  }, [controller, gtlTrades]);

  React.useEffect(() => {
    if (companyInfo.size) controller.setCompanyInfo(companyInfo);
  }, [controller, companyInfo]);

  return {
    state,
    controller,
  };
};
