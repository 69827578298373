import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import navigate from 'app/navigate';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipStatus, colors, STATUS_MAP } from '@tradetrax/web-common';
import { CriticalPathIcon } from '@tradetrax/web-common/lib/CriticalPath';
import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { UpdateRequestStatus } from '@tradetrax/web-common/lib/Dashboard/Upcoming/UpdateRequestStatus';
import { PreConstructionIcon } from '@tradetrax/web-common/lib/Popover/PreConstructionIcon';
import { MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { PopoverMultiFamily } from '@tradetrax/web-common/lib/Popover/MultiFamily.jobs.popover';

const JobName = ({ task }) => {
  const job = task.get('job');
  const jobId = job.get('id');
  const lotNumber = task.getIn(['job', 'lotNumber']);
  const truncatedLotNumber = lotNumber?.length > 10 ? lotNumber.slice(0, 10) + '...' : lotNumber;

  if (task.get('isMultiFamily')) {
    return (
      <PopoverMultiFamily multiFamily={task.get('multiFamily')}>
        <span className="clickable-text text-gray">{task.getIn(['multiFamily', 'name'])}</span>
      </PopoverMultiFamily>
    );
  }

  return (
    <Link className="text-nowrap text-truncate  text-gray-800" to={navigate.to.JobDetails({ jobId }, false)}>
      {lotNumber ? (
        <span className="truncateLink text-truncate ">{`LOT #${truncatedLotNumber}`}</span>
      ) : (
        job.get('name')
      )}
    </Link>
  );
};

export const UpcomingCard = ({ task, showPrecedingTasks, controller, assigneeProps }) => {
  const status = task.get('checkedIn') ? 'checked-in' : task.get('status');
  const taskId = task.get('id');
  const job = task.get('job');
  const jobId = job.get('id');
  const isStartDateConfirmed = !!task.get('startDateConfirmed');
  const isCritical = task.get('isCritical');
  const builderName = task.getIn(['job', 'builderName']);
  const communityName = task.get('communityName');
  const communityId = task.get('communityId');
  const createdBySub = task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'sub';

  useChannel(CHANNELS.TASK, `${jobId}_${taskId}`, controller.getTaskDetails);

  return (
    <Card>
      <div className="left-container">
        <div className="d-flex flex-column justify-content-center  mr-auto info-task">
          <div className="d-flex align-items-center ">
            <Link
              className="font-weight-bold text-truncate text-gray-800 task_name"
              to={navigate.to.TaskDetails({ status, jobId, taskId }, false)}
            >
              {task.get('name')}
            </Link>
            {isCritical && <CriticalPathIcon />}
            <MultiFamilyIcon task={task} />
            <PreConstructionIcon task={task} />
          </div>
          <div className="d-flex" style={{ maxWidth: '100%' }}>
            <JobName task={task} />
          </div>
          <CommunityContainer className="text-gray-800 font-size-14 d-flex text-nowrap  ">
            <CommunityLink
              to={navigate.to.CommunityDetails({ communityId: communityId }, false)}
              className="text-nowrap  text-truncate"
            >
              {communityName}
            </CommunityLink>
            <span className="mx-1 text-gray-400">{` by `}</span>
            <span className=" d-inline-block text-truncate">{builderName}</span>
          </CommunityContainer>
        </div>
        <div className="d-flex flex-row assignee-container">
          <span>
            <AssigneeSelect {...assigneeProps} />
          </span>
        </div>
      </div>
      <div className="right-container">
        <div className="d-flex justify-content-center">
          <span>{createdBySub && <UpdateRequestStatus task={task} />}</span>
          <span>
            <FontAwesomeIcon
              icon="check"
              className={cn('mx-2', {
                'text-success': isStartDateConfirmed,
                'text-gray-200': !isStartDateConfirmed,
              })}
            />
          </span>
          <span className="font-size-14 text-nowrap">{task.get('expectedStartLabel')}</span>
        </div>
        <span className="">
          <ChipStatus {...STATUS_MAP[status]} />
        </span>
        <Preceding task={task} showPrecedingTasks={showPrecedingTasks} />
      </div>
    </Card>
  );
};

const PRECEDING_STATUS_MAP = {
  'ready-to-start': ['Ready', 'to Start'],
  'almost-ready-to-start': ['Almost Ready', 'to Start'],
  'not-ready-to-start': ['Not Ready', 'to Start'],
  'not-ctr': ['No Definition', 'of Ready'],
};

const Preceding = ({ task, showPrecedingTasks }) => {
  const ctr = task.get('ctr');
  const ctrStatus = ctr.get('status');
  const inJobPrecedingTasksNumber = ctr.get('inJobPrecedingTasksNumber');
  const resolvedPrecedingTasksNumber = ctr.get('resolvedPrecedingTasksNumber');
  const [line1, line2] = PRECEDING_STATUS_MAP[ctrStatus];
  const showWarning = ctr.get('precedingTasksNotOnJob');

  return (
    <div onClick={() => showPrecedingTasks(task)} className={`d-flex  preceding ${ctrStatus}`}>
      <div className="position-relative preceding_number d-flex align-items-center">
        <Warning show={showWarning} />
        <div className={`badge ${ctrStatus}`}>
          {resolvedPrecedingTasksNumber}/{inJobPrecedingTasksNumber}
        </div>
      </div>
      <span className={`font-weight-bold label-status ${ctrStatus}`}>
        {line1}
        <br />
        {line2}
      </span>
    </div>
  );
};

const UnstyledWarning = ({ show, className }) => {
  if (!show) return null;
  return (
    <span className={className}>
      <FontAwesomeIcon icon="exclamation" className="mx-auto" />
    </span>
  );
};

const Warning = styled(UnstyledWarning)`
  background-color: ${colors.yellow100};
  display: block;
  font-size: 8px;
  border-radius: 50%;
  color: white;
  width: 13px;
  height: 12px;
  left: -20px;
  position: absolute;
`;

const Card = styled.div`
  flex: 1 1 auto !important;
  flex-direction: row;
  min-height: 80px;
  background-color: ${colors.pearl};
  border: solid 1px ${colors.gray};
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  margin-bottom: 12px;
  padding-left: 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;

  @media (min-width: 1200px) {
    padding: 10px 0;
    max-width: 1100px;
  }

  .left-container {
    display: flex;
    flex-direction: column;
    padding: 21px;
    max-width: 50%;
    @media (min-width: 1200px) {
      width: 50%;
      padding: 0 21px;
      flex-direction: row;
      justify-content: space-between;
    }

    .task_name {
      max-width: 100%;
    }
    .info-task {
      max-width: 100%;
      @media (min-width: 1200px) {
        min-width: 0;
      }

      & > :nth-child(-n + 3) {
        margin-bottom: 5px;
      }
    }

    .assignee-container {
      @media (min-width: 1200px) {
        margin-left: 10px;
      }
    }
  }

  .right-container {
    padding: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > :nth-child(-n + 3) {
      margin-bottom: 5px;
    }

    & > :nth-child(1) {
      order: 2;
      margin-bottom: 13px;
    }
    & > :nth-child(2) {
      order: 3;
    }
    & > :nth-child(3) {
      order: 1;
    }

    @media (min-width: 1200px) {
      width: 50%;
      padding: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > :nth-child(1) {
        order: 1;
        margin: 0;
      }
      & > :nth-child(2) {
        order: 2;
        margin: 0;
      }
      & > :nth-child(3) {
        order: 3;
        margin: 0;
      }
    }

    > .preceding {
      width: 130px;
      text-align: center;
      font-size: 12px;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      @media (min-width: 1200px) {
        border-left: solid 1px ${colors.gray};
        display: flex;
        flex-direction: column;
      }

      &:hover {
        background-color: ${colors.activeState};
      }

      .preceding_number {
        margin-right: 5px;
      }
    }
  }

  a,
  .truncateLink {
    text-decoration: none;

    &:hover {
      color: ${colors.blue300};
      text-decoration: underline;
    }
  }

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .label-status {
    &.ready-to-start {
      color: ${colors.green300};
    }

    &.almost-ready-to-start {
      color: ${colors.gray200};
    }

    &.not-ready-to-start {
      color: ${colors.red};
    }

    &.not-ctr {
      color: ${colors.gray400};
    }
  }

  .badge {
    color: ${colors.white};
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border-style: none;

    &.ready-to-start {
      background-color: ${colors.green300};
    }

    &.almost-ready-to-start {
      background-color: ${colors.gray200};
    }

    &.not-ready-to-start {
      background-color: ${colors.red};
    }

    &.not-ctr {
      background-color: ${colors.gray400};
    }
  }
`;

const CommunityLink = styled(Link)`
  text-decoration: none;
  color: ${colors.gray800};
  &:hover {
    text-decoration: underline;
    color: ${colors.blue300} !important;
  }
`;

const CommunityContainer = styled.div`
  max-width: 250px;
  @media (min-width: 870px) {
    max-width: 305px;
  }
`;
