import React, { useEffect } from 'react';
import moment from 'moment';
import { fromJS, List } from 'immutable';
import { DialogController, NotificationsController, useController, markAsSideEffect } from '@tradetrax/web-common';
import { AppContext, useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
import { useEvent } from '@tradetrax/web-common/lib/useEvents';
import { emptyAccount } from './entities';
import navigate from './navigate';
import { history } from './navigate';
import { usersService, subsService } from '../services';
import * as appActions from './appActions';
import { invalidateCounters, getTaskCounterActions } from '@tradetrax/web-common/lib/TaskCounters/TaskCounters.actions';
import { useSocket, useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { redirectToLogin } from '@tradetrax/web-common';

export { AppContext, useAppContext };

const actions = {
  ...appActions,
  ...getTaskCounterActions(subsService),
};

const emptyState = fromJS({
  account: emptyAccount,
  trades: List(),
  expanded: true,
  cookieBannerAccepted: true,
  globalSearchResults: [],
  filteredResults: [],
  counters: {
    overdueCount: -1,
    requestCount: -1,
    toDoEndCount: -1,
    toDoStartCount: -1,
    startDateConfirmationRequestCount: -1,
  },
});

export function Context(user) {
  const initialState = React.useMemo(() => emptyState.set('user', fromJS(user)), [user]);
  const modal = DialogController();
  const miniAlertsRef = React.useRef();
  const alert = miniAlertsRef.current;
  const [appState, appController] = useController(actions, initialState, {
    errorMessage,
    modal,
    alert,
    miniAlertsRef,
    dashboardService: subsService,
    isTrade: true,
    invalidateCounters,
  });
  const notifications = NotificationsController(subsService, usersService, fromJS(user), alert);
  const account = appState.get('account');
  const areCookiesAccepted = appState.get('cookieBannerAccepted');
  const { acceptCookies } = appController;

  useSocket(user);
  // eslint-disable-next-line
  useEffect(initialize, []);
  useEffect(() => {
    if (!areCookiesAccepted) miniAlertsRef.current.showAcceptCookiesBanner(acceptCookies);
  }, [areCookiesAccepted, miniAlertsRef, acceptCookies]);

  const { legalAgreements } = account.toObject();
  useEffect(() => {
    if (legalAgreements?.size) {
      const isAcceptLegalAgreements = !Object.keys(legalAgreements.toJS()).some(
        agreement => agreement && !legalAgreements.get(agreement)
      );
      if (!isAcceptLegalAgreements) appController.openLegalAgreementModal();
    }
  }, [legalAgreements, appController]);

  const hasRole = role => appState.getIn(['user', 'role']) === role;
  const hasPermission = (key, value = 'yes') => appState.getIn(['user', 'permissions', key]) === value;
  const getPermission = key => appState.getIn(['user', 'permissions', key]);
  useEvent(
    'notifications:save-token',
    React.useCallback(
      token =>
        appController.dispatch([
          state => state.updateIn(['user', 'tokenDevices'], tokenDevices => fromJS([...tokenDevices, token])),
        ]),
      [appController]
    )
  );

  //TODO move this function to actions file - confirm changes with BE payload updated
  const refreshUser = React.useMemo(
    () => ({ action }) => {
      console.log('user changed', action);
      if (action === 'update') {
        usersService
          .info()
          .catch(() => redirectToLogin())
          .then(user => appController.dispatch([state => state.set('user', fromJS(user))]));
      }
    },
    [appController]
  );

  // const refreshCounters = React.useMemo(() => foo => appController.readTaskCounters(), [appController]);

  useChannel(CHANNELS.SELF, user._id, refreshUser);
  // useChannel(CHANNELS.ACCOUNT_TASK, user.accountId, refreshCounters);
  if (global.Cypress) {
    global.isAlertReady = !!alert;
  }

  return {
    appState,
    appController,
    navigate,
    modal,
    alert,
    notifications,
    account,
    errorMessage: errorMessage(miniAlertsRef),
    hasRole,
    hasPermission,
    getPermission,
    addAlert: addAlert(miniAlertsRef),
    miniAlertsRef,
    isUserInactive: isUserInactive(appState.get('account')),
    invalidateCounters: counters => invalidateCounters.call({ controller: appController }, counters),
  };

  function initialize() {
    appController.initializeAccount();
    appController.readTrades();
    appController.getCookies();
    appController.readTaskCounters();

    setInterval(() => {
      appController.readTaskCounters();
    }, 120000); //120 secs

    if (navigator && navigator.serviceWorker) {
      const notificationsController = notifications[1];
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.type === 'notification') {
          console.log('app controller message => ', event.data, event.data.url);
          notificationsController.addMessage(event.data);
          history.push(event.data.url);
        }
        // else => it's a direct notification coming from FCN and will be handled on notifications controller.
      });
    }
  }
}

function addAlert(alertRef) {
  return function(message, variant = 'success', defer = 100) {
    setTimeout(() => {
      const alert = alertRef.current;
      if (alert) {
        alert.add({ message, variant });
      }
    }, defer);
  };
}

// this is intended to be used on Promise.catch
// .catch(errorMessage('rat'))
function errorMessage(alertRef) {
  return function(message) {
    return () => {
      const alert = alertRef.current;
      if (alert) {
        alert.add({ message, variant: 'danger' });
      }
      return state => state;
    };
  };
}

function isUserInactive(account) {
  return user => {
    return !account.get('assigneesActive').find(assignee => assignee.get('_id') === user.get('_id'));
  };
}

markAsSideEffect(readTaskCounters);
function readTaskCounters() {
  const prev = this.state.get('counters');
  return subsService
    .readTaskCounters({}, { query: { date: moment().format('YYYY-MM-DD') } })
    .then(({ taskCounters }) => {
      const next = fromJS(taskCounters);
      this.controller.dispatch([state => state.set('counters', next)]);
      return {
        prev,
        next,
      };
    });
}
