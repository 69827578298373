import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mongoToTextYearComparison, calculateTimeLapse } from '@tradetrax/web-common/lib/utils';

export function TaskChangeRequestReview({ task, className = '' }) {
  const isChangeRequestOnReviewByTrade =
    task.getIn(['changeRequest', 'state']) === 'review-by-sub' &&
    task.getIn(['changeRequest', 'activeForCurrentUser']) &&
    !task.get('startDateConfirmationRequest');

  const isChangeRequestOnReviewByBuilder =
    task.getIn(['changeRequestOnReviewByBuilder', 'state']) === 'review-by-builder';

  if (!isChangeRequestOnReviewByTrade && !isChangeRequestOnReviewByBuilder) return null;
  if (task.getIn(['changeRequestOnReviewByBuilder', 'createdByType']) === 'builder') return null;

  const showDoubleUptReq =
    task.getIn(['changeRequestOnReviewByBuilder', 'newEndDateProposed']) &&
    task.getIn(['changeRequestOnReviewByBuilder', 'type']) === 'new-start-date-request';
  const { changeRequestType, changeRequestDate, createdOn } = getChangeRequestData(task);

  if (showDoubleUptReq) {
    const { changeRequestDateStart, changeRequestDateFinish } = getChangeRequestData(task, true);
    return (
      <ChangeRequestContainer
        className={className}
        changeRequests={[
          { label: 'Proposed Start', date: changeRequestDateStart },
          { label: 'Proposed Finish', date: changeRequestDateFinish },
        ]}
        builderName={task.getIn(['job', 'builderName'])}
        createdOn={createdOn}
      />
    );
  }

  return (
    <ChangeRequestContainer
      className={className}
      changeRequests={[{ label: `Proposed ${changeRequestType}`, date: changeRequestDate }]}
      builderName={task.getIn(['job', 'builderName'])}
      createdOn={createdOn}
    />
  );
}

const ChangeRequestContainer = ({ className, changeRequests, builderName, createdOn }) => {
  return (
    <div className={`d-flex flex-column pt-3 ${className}`} data-testid="div-change-request-review">
      {changeRequests.map((request, index) => (
        <div key={index} className="bg-pearl rounded-6 p-3 mb-2">
          <div className="d-flex flex-row justify-content-between">
            <strong className="font-size-14">{request.label}</strong>
            <span>{mongoToTextYearComparison(request.date)}</span>
          </div>
          <span className="text-secondary font-size-14">
            <FontAwesomeIcon icon="clock" className="mr-2" />
            Pending approval on <span className="text-dark">{builderName}</span>
          </span>
        </div>
      ))}
      <span className="align-self-end mt-1 font-size-14">{`Sent ${calculateTimeLapse(createdOn)}`}</span>
    </div>
  );
};

function getChangeRequestData(task, showDoubleUptReq = false) {
  const defaultChangeRequest = task.get('changeRequestOnReviewByBuilder') || task.get('changeRequest');
  const changeRequest = task.get('startDateConfirmationRequest')
    ? task.get('changeRequestOnReviewByBuilder')
    : defaultChangeRequest;
  const type = changeRequest.get('type');

  if (showDoubleUptReq) {
    return {
      changeRequestDateStart: changeRequest.get('proposedStartDate'),
      changeRequestDateFinish: changeRequest.get('proposedFinishDate'),
    };
  }

  if (type === 'new-start-date-request') {
    return {
      changeRequestDate: changeRequest.get('proposedStartDate'),
      changeRequestType: 'Start',
      createdOn: changeRequest.get('createdOn'),
    };
  }
  //if (type === 'new-end-date-request') {
  return {
    changeRequestDate: changeRequest.get('proposedFinishDate'),
    changeRequestType: 'Finish',
    createdOn: changeRequest.get('createdOn'),
  };
}
